module.exports = {
	footerText: 'All rights reserved by - The Church of Pentecost – General Headquarters',

	apiserver: 'https://apiv3.penterp.com/api/',


	// apiserver: 'https://fmsapi.yottaerp.com/api/',

	profile_pic_url: 'https://apiv3.penterp.com/public/upload/profile/',

	// detailsImgURL: 'https://fmsapi.yottaerp.com/public/upload/support_article/',
	detailsImgURL: 'https://apiv3.penterp.com/public/upload/support_article/',
	
	logourl : '/assets/images/coplogo.png',
	print_logourl : '/assets/images/cop_nlogo.png',
	company_name : 'The Church of Pentecost – General Headquarters',
	address : 'Wayne, New Jersey, USA',
	city : 'Wayne',
	state : 'New Jersey',
	zipcode : '11111',
	phone : "",
	email : "info@thecophq.org",
	website : "https://thecophq.org"
}